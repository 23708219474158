var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-8 pr-8"},[_c('div',{staticClass:"mb-xl-margin-file-sign"},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"text-left navidad-text",attrs:{"cols":"6"}},[_c('h1',{staticClass:"reference__title"},[_vm._v("Crear proceso disciplinario")])])],1),_c('v-form',{ref:"form"},[_c('v-card',{staticClass:"reference__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":150,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"label":"Seleccionar fecha*","prepend-inner-icon":"mdi-calendar","readonly":"","required":""},model:{value:(_vm.form.selectedDate),callback:function ($$v) {_vm.$set(_vm.form, "selectedDate", $$v)},expression:"form.selectedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate,"min":_vm.minDate},model:{value:(_vm.form.selectedDate),callback:function ($$v) {_vm.$set(_vm.form, "selectedDate", $$v)},expression:"form.selectedDate"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){(_vm.menu = false), (_vm.form.selectedDate = null)}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuHora",attrs:{"close-on-content-click":false,"nudge-right":150,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"label":"Seleccionar hora*","prepend-inner-icon":"mdi-clock-outline","readonly":"","required":""},model:{value:(_vm.form.selectedTime),callback:function ($$v) {_vm.$set(_vm.form, "selectedTime", $$v)},expression:"form.selectedTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHora),callback:function ($$v) {_vm.menuHora=$$v},expression:"menuHora"}},[_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.form.selectedTime),callback:function ($$v) {_vm.$set(_vm.form, "selectedTime", $$v)},expression:"form.selectedTime"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){(_vm.menuHora = false), (_vm.form.selectedTime = null)}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"prepend-inner-icon":"mdi-account-outline","color":"#466be3","items":_vm.listUserAll,"item-text":"user","item-value":"document_number","label":"Nombre - Número de cédula - Usuario de red","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"small":"","dark":"","color":"#466be3"}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.user)+" ")])]}}]),model:{value:(_vm.form.listUserAllId),callback:function ($$v) {_vm.$set(_vm.form, "listUserAllId", $$v)},expression:"form.listUserAllId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"name":"input-7-1","label":"Motivo*","counter":"","value":"","rows":"4","required":""},model:{value:(_vm.form.motivo),callback:function ($$v) {_vm.$set(_vm.form, "motivo", $$v)},expression:"form.motivo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mt-2 ml-2 mb-5",staticStyle:{"color":"#fff"},style:({
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.createProcess()}}},[_vm._v(" Crear proceso ")])]}}])})],1)],1)],1)],1)],1),_c('Alert',{attrs:{"open":_vm.message.dialog,"text":_vm.message.sms,"title":_vm.message.title,"type":_vm.message.type,"redirect":_vm.message.redirect},on:{"close":function($event){_vm.message.dialog = false}}}),_c('v-overlay',{attrs:{"value":_vm.loadingAll}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#466BE3","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.$translate("general.loading"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }